@import "./Mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Colors
$primary-color: #9657c8;
$light-primary-color: #9fca3f96;
$secondary-color: #707070;
$link-color: #0000ff;
$light-secondary-color: #6dade6;
$danger-color: #f04134;
$success-color: #7bc142;
$info-color: #a4d9d3;

$font-color: #222222;

$placeholder-text-color: #9e9e9e;

$heading-color: #383838;

$light-gray: #a7a7a7;

$black: #000;
$white: #ffffff;
$border-color: #cecece;
$gray: #5f5f5f;
$dark-gray: #787878;
$medium-gray: #a5a5a5;
// $light-gray: #f8f8f8;

$bg-color: #e4e0da40;
$blue: #202c55;

//Font Family
$title-font: "Inter", sans-serif;
$font-family: "Inter", sans-serif;

$page-background: #f3f4f5;

$sidebar-text-color: $white;
$header-color: $black;

$success-light-bg-color: #f1ffea;
$success-light-border-color: #22a203;
$danger-light-bg-color: #ffe3de;
$danger-light-border-color: #e81c01;
$secondary-light-bg-color: #f0f0f0;
$secondary-light-border-color: #696969;

// We have multiple shadow levels to represent perspective
$shadow-0: 0 2px 2px rgba(0, 0, 0, 0.1);
$shadow-1: 2px 4px 6px rgba(0, 0, 0, 0.1);
* {
  transition: box-shadow 0.1s ease-in;
}

$header-height: 77px;
@import "./Markdown.scss";

//fonts
// $font-primary: 'Inter', sans-serif;
$font-primary: "Roboto", sans-serif;
