@import "./Variables.scss";

.input-theme {
  color: $primary-color;
  border: 1px solid $primary-color;
  min-height: 49px;
  box-shadow: none;

  &:hover {
    border-color: $light-primary-color !important;
  }
  input {
    font-size: 1.2rem;
    &::placeholder {
      font-size: 1.2rem;
    }
  }
}
.input-icon {
  font-size: 1.4rem !important;
}

.search-input-theme {
  span {
    input {
      border: 1px solid $primary-color;
      min-height: 49px;
      &::placeholder {
        font-size: 1.2rem;
      }
    }
    span {
      button {
        font-size: 1rem;
        padding: 1.2rem;
        min-height: 49px !important;
        font-size: 1.2rem;
        border-left: none;
        border-color: $primary-color;
        @extend .d-flex, .align-items-center, .justify-content-center;
        &:where(.css-dev-only-do-not-override-sk7ap8).ant-btn.ant-btn-icon-only {
          min-width: 50px;
        }
      }
    }
  }
}

.theme-btn {
  color: $white;
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: $primary-color;
  transition: all 0.2s ease-in-out;

  @extend .d-flex, .h-100, .align-items-center, .justify-content-center;
  .icon {
    color: $white;
    border: 1px solid $white;
    transition: all 0.5s ease-in-out;
    @extend .p-1, .rounded-circle;
  }
  &:hover {
    color: $primary-color !important;
    background-color: $white !important;
    border-color: $primary-color !important;
    .icon {
      color: $primary-color !important;
      background-color: $white !important;
      border-color: $primary-color !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .input-theme {
    min-height: 38px;
    box-shadow: none;
    input {
      font-size: 1rem;
      &::placeholder {
        font-size: 0.8rem !important;
      }
    }
  }
  .search-input-theme {
    span {
      input {
        min-height: 38px;
      }
      span {
        button {
          min-height: 40px !important;
        }
      }
    }
  }

  .theme-btn {
    color: $white;
    font-size: 0.9 rem;
    padding: 0.6rem;
  }
}

.ant-table-thead .ant-table-cell {
  color: white !important;
  background-color: #323232 !important;
}
.modalStyle .ant-modal-content {
  background-color: #323232;
  color: white !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-table-wrapper
  .ant-table-column-sorter {
  color: white !important;
}
